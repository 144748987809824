import React, { useEffect, useState } from 'react';
import './Leaders.css';

const Leaders = () => {
  const [leaders, setLeaders] = useState([]);

  useEffect(() => {
    const fetchLeaders = async () => {
      try {
        const response = await fetch('https://svrgg.sp1project.ru/api/leaders'); 
        const data = await response.json();
        setLeaders(data);
      } catch (error) {
        console.error('Error fetching leaders:', error);
      }
    };

    fetchLeaders();

    if (window.Telegram && window.Telegram.WebApp) {
      const backButton = window.Telegram.WebApp.BackButton;

      backButton.show();
      backButton.onClick(() => {
        window.history.back();
        backButton.hide(); 
      });

      return () => {
        backButton.offClick();
        backButton.hide(); 
      };
    }
  }, []);

  return (
    <><div id="root">
        <div id="wrap">
          </div>
            </div>
    <div class="header-community">
    <div class="header-tabs">
        <a href="/frens" style={{ color: 'rgb(255, 255, 255)', }}>Друзья</a>
        <a href="/leaders" style={{  background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)'}}>Лидеры</a>
    </div>
    </div>
    <div className="header-titlee">
      <h4>Лидеры</h4>
      <p> 
        Моя позиция 
      </p>
      <div class="home-wrappeer">
        <div class="stars">
          <div class="star" style={{left: "70.1342vw", animationduration: "23.7419s", animationdelay: "30.128s"}}></div>
          <div class="star" style={{left: "11.3752vw", animationduration: "19.5901s", animationdelay: "19.7733s"}}></div>
          <div class="star" style={{left: "3.14422vw", animationduration: "27.4916s", animationdelay: "21.4399s"}}></div>
          <div class="star" style={{left: "35.055vw", animationduration: "12.6897s", animationdelay: "22.1835s"}}></div>
          <div class="star" style={{left: "64.3744vw", animationduration: "17.9689s", animationdelay: "22.026s"}}></div>
          <div class="star" style={{left: "75.7224vw", animationduration: "28.0009s", animationdelay: "13.4231s"}}></div>
          <div class="star" style={{left: "29.1746vw", animationduration: "13.381s", animationdelay: "9.40372s"}}></div>
          <div class="star" style={{left: "79.6336vw", animationduration: "23.7636s", animationdelay: "34.4766s"}}></div>
          <div class="star" style={{left: "82.0162vw", animationduration: "27.3175s", animationdelay: "26.2103s"}}></div>
          <div class="star" style={{left: "1.41162vw", animationduration: "19.1289s", animationdelay: "7.6136s"}}></div>
          <div class="star" style={{left: "50.2198vw", animationduration: "26.0729s", animationdelay: "27.0773s"}}></div>
          <div class="star" style={{left: "24.998vw", animationduration: "21.3097s", animationdelay: "8.08356s"}}></div>
          <div class="star" style={{left: "56.185vw", animationduration: "21.4884s", animationdelay: "22.0578s"}}></div>
          <div class="star" style={{left: "26.269vwv", animationduration: "12.5101s", animationdelay: "9.16564s"}}></div>
          <div class="star" style={{left: "92.4952vw", animationduration: "15.3216s", animationdelay: "2.84211s"}}></div>
          <div class="star" style={{left: "84.4193vw", animationduration: "26.9083s", animationdelay: "25.0645s"}}></div>
          <div class="star" style={{left: "76.5969vw", animationduration: "14.5449s", animationdelay: "0.702624s"}}></div>
          <div class="star" style={{left: "10.4995vw", animationduration: "28.9362s", animationdelay: "21.729s"}}></div>
          <div class="star" style={{left: "10.2466vw", animationduration: "14.7487s", animationdelay: "34.0715s"}}></div>
          <div class="star" style={{left: "57.9147vw", animationduration: "26.477s", animationdelay: "9.66715s"}}></div>
          <div class="star" style={{left: "44.5344vw", animationduration: "23.8619s", animationdelay: "27.7768s;"}}></div>
          <div class="star" style={{left: "75.5054vw", animationduration: "13.6861s", animationdelay: "7.7282s;"}}></div>
          <div class="star" style={{left: "75.7586vw", animationduration: "15.8217s", animationdelay: "37.3606s"}}></div>
          <div class="star" style={{left: "13.2603vw", animationduration: "20.2189s", animationdelay: "24.9501s;"}}></div>
          <div class="star" style={{left: "24.8483vw", animationduration: "15.8192s", animationdelay: "20.1782s;"}}></div>
          <div class="star" style={{left: "43.2092vw", animationduration: "18.5368s", animationdelay: "26.3506s;"}}></div>
          <div class="star" style={{left: "90.9408vw", animationduration: "20.9069s", animationdelay: "31.1649s;"}}></div>
          <div class="star" style={{left: "52.6467vw", animationduration: "27.3346s", animationdelay: "19.9558s;"}}></div>
          <div class="star" style={{left: "60.8038vw", animationduration: "11.1217s", animationdelay: "29.0269s;"}}></div>
          <div class="star" style={{left: "18.9283vw", animationduration: "21.0515s", animationdelay: "0.747841s;"}}></div>
          <div class="star" style={{left: "37.8164vw", animationduration: "12.3809s", animationdelay: "17.0925s;"}}></div>
          <div class="star" style={{left: "53.1046vw", animationduration: "25.4653s", animationdelay: "25.8693s"}}></div>
          <div class="star" style={{left: "74.172vw",animationduration: "16.4769s", animationdelay: "0.143656s"}}></div>
          <div class="star" style={{left: "96.863vw" , animationduration: "20.7103s", animationdelay: "27.124s"}}></div>
          <div class="star" style={{left: "44.0458vw", animationduration: "18.1801s", animationdelay: "5.06372s"}}></div>
          <div class="star" style={{left: "51.2625vw", animationduration: "10.0218s", animationdelay: "38.9676s"}}></div>
          <div class="star" style={{left: "49.572vw" , animationduration:  "11.6766s", animationdelay: "11.853s"}}></div>
          <div class="star" style={{left: "6.90703vw", animationduration: "14.6367s", animationdelay: "4.95851s"}}></div>
          <div class="star" style={{left: "63.8325vw", animationduration: "18.4147s", animationdelay: "32.503s"}}></div>
          <div class="star" style={{left: "39.3277vw", animationduration: "29.5293s", animationdelay: "38.9926s"}}></div>
          <div class="star" style={{left: "95.8286vw", animationduration: "15.7414s", animationdelay: "34.934s"}}></div>
          <div class="star" style={{left: "15.3457vw", animationduration: "17.7165s", animationdelay: "25.5179s"}}></div>
          <div class="star" style={{left: "55.5485vw", animationduration: "15.4251s", animationdelay: "31.8784s"}}></div>
          <div class="star" style={{left: "91.8554vw", animationduration: "27.0239s", animationdelay: "17.0857s"}}></div>
          <div class="star" style={{left: "18.4403vw", animationduration: "25.5412s", animationdelay: "38.3449s"}}></div>
          <div class="star" style={{left: "97.4484vw", animationduration: "12.4715s", animationdelay: "26.1737s"}}></div>
          <div class="star" style={{left: "46.745vw", animationduration: "27.1926s", animationdelay: "32.9843s"}}></div>
          <div class="star" style={{left: "25.0871vw", animationduration: "10.5799s", animationdelay: "37.4537s"}}></div>
          <div class="star" style={{left: "99.7217vw", animationduration: "16.7533s", animationdelay: "3.23861s"}}></div>
          <div class="star" style={{left: "72.0561vw", animationduration: "25.6895s", animationdelay: "8.23535s"}}></div>
          <div class="star" style={{left: "36.2862vw", animationduration: "24.2709s", animationdelay: ".3403s"}}></div>
          <div class="star" style={{left: "46.4477vw", animationduration: "25.1982s", aimationdelnay: "11.2975s"}}></div>
          <div class="star" style={{left: "98.0884vw", animationduration: "20.3786s", animationdelay: "4.77183s"}}></div>
          <div class="star" style={{left: "4.1595vw", animationduration: "13.4508s", animationdelay: "15.6929s"}}></div>
          <div class="star" style={{left: "87.0951vw", animationduration: "20.3713s", animationdelay: "7.70025s"}}></div>
          <div class="star" style={{left: "67.2375vw", animationduration: "23.1319s" , animationdelay: "0.541492s"}}></div>
          <div class="star" style={{left: "8.37497vw", animationduration: "14.4385s", animationdelay: "18.724s"}}></div>
          <div class="star" style={{left: "19.1027vw", animationduration: "27.726s", animationdelay: "16.817s"}}></div>
          <div class="star" style={{left: "53.3297vw", animationduration: "20.8768s", animationdelay: "21.9441s"}}></div>
          <div class="star" style={{left: "58.7787vw", animationduration: "23.1052s" , animationdelay: "13.1826s"}}></div>
          <div class="star" style={{left: "69.5075vw", animationduration: "16.9005s", animationdelay: "5.3423s"}}></div>
          <div class="star" style={{left: "67.069vw" ,animationduration: "13.8813s", animationdelay: "29.9685s"}}></div>
          <div class="star" style={{left: "2.31521vw", animationduration: "26.8046s", animationdelay: "29.8534s"}}></div>
          <div class="star" style={{left: "12.2258vw", animationduration: "14.6571s", animationdelay: "3.4164s"}}></div>
          <div class="star" style={{left: "54.6959vw", animationduration: "15.9071s", animationdelay: "35.4304s"}}></div>
          <div class="star" style={{left: "18.1622vw", animationduration: "25.2823s", animationdelay: "24.6953s"}}></div>
          <div class="star" style={{left: "24.9094vw", animationduration: "16.2479s", animationdelay: "39.1002s"}}></div>
          <div class="star" style={{left:"81.402vw", animationduration: "20.5563s", animationdelay: "1.98788s" }}></div>
          <div class="star" style={{left: "69.659vw", animationduration: "15.2428s", animationdelay: "34.1293s"}}></div>
          <div class="star" style={{left: "89.7099vw" , animationduration: "21.4865s", animationdelay: "36.0475s"}}></div>
          </div>
      </div>
      <ul>
        {leaders.length > 0 ? (
          leaders.map((leader, index) => (
            <li key={index}>
              {index + 1}. {leader.first_name} - {leader.coins} монет
            </li>
          ))
        ) : (
          <li>Загрузка...</li>
        )}
      </ul>
                <footer class="footer">
                  <div class="main-menu">
                    <a href="/">
                      <div class="menu-item first">
                        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.0579 2.53472C9.18732 1.69936 7.81281 1.69936 6.94224 2.53472L2.3158 6.97405C2.20245 7.08282 2.1262 7.22451 2.09787 7.37903C1.54373 10.4009 1.50283 13.4945 1.97687 16.53L2.08939 17.2505H5.06585V11.0391C5.06585 10.6249 5.40164 10.2891 5.81585 10.2891H11.1843C11.5985 10.2891 11.9343 10.6249 11.9343 11.0391V17.2505H14.9107L15.0232 16.53C15.4973 13.4945 15.4564 10.4009 14.9023 7.37903C14.8739 7.22451 14.7977 7.08282 14.6843 6.97406L10.0579 2.53472ZM5.90369 1.4524C7.35464 0.0601368 9.64549 0.0601368 11.0964 1.4524L15.7229 5.89174C16.0634 6.21853 16.2925 6.64422 16.3777 7.10848C16.9622 10.2961 17.0053 13.5594 16.5053 16.7614L16.3245 17.9189C16.2498 18.3976 15.8375 18.7505 15.353 18.7505H11.1843C10.7701 18.7505 10.4343 18.4147 10.4343 18.0005V11.7891H6.56585V18.0005C6.56585 18.4147 6.23007 18.7505 5.81585 18.7505H1.64712C1.16264 18.7505 0.750355 18.3976 0.6756 17.9189L0.494837 16.7614C-0.00521103 13.5594 0.0379363 10.2961 0.622466 7.10848C0.707599 6.64422 0.936683 6.21853 1.27725 5.89173L5.90369 1.4524Z" fill="#5577AA" fill-rule="evenodd" clip-rule="evenodd"></path>
                        </svg>
                        <span style={{ color: 'rgb(85, 119, 170)' }}>Home</span>
                      </div>
                    </a>
                    <div class="center-container">
                      <a href="/tasks">
                        <div class="menu-item 2">
                          <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00666 0.508036C6.18839 0.298945 6.47057 0.207052 6.74057 0.269038C10.3494 1.09755 12.1735 5.05217 11.7445 8.5903C11.5778 9.96568 11.1658 11.0439 10.5592 11.9018C10.5555 11.9071 10.5517 11.9123 10.548 11.9175C10.6858 11.8498 10.8129 11.7767 10.9319 11.6995C11.5238 11.3153 11.9583 10.8083 12.5239 10.1481C12.5923 10.0683 12.6626 9.98629 12.7353 9.90196C12.8987 9.71247 13.1459 9.61695 13.3942 9.64729C13.6426 9.67764 13.8595 9.82985 13.9725 10.0531C14.47 11.0363 14.75 12.1479 14.75 13.3226C14.75 17.3267 11.5041 20.5726 7.5 20.5726C3.49594 20.5726 0.25 17.3267 0.25 13.3226C0.25 10.7856 1.55371 8.5535 3.52431 7.25906L3.59187 7.19098C3.62658 7.15601 3.66464 7.12454 3.70552 7.09703C4.57138 6.5144 5.19312 6.03786 5.61171 5.47743C6.00713 4.94801 6.25 4.3022 6.25 3.32264C6.25 2.59736 6.11608 1.90512 5.87229 1.26809C5.77327 1.00936 5.82494 0.717126 6.00666 0.508036ZM7.67122 2.25081C7.72313 2.60079 7.75 2.95872 7.75 3.32264C7.75 4.59106 7.42229 5.55995 6.81349 6.37505C6.24173 7.14054 5.45028 7.72847 4.60466 8.2999L4.53236 8.37275C4.49362 8.41179 4.45071 8.44644 4.40439 8.4761C2.80648 9.49913 1.75 11.2877 1.75 13.3226C1.75 16.4983 4.32436 19.0726 7.5 19.0726C10.6756 19.0726 13.25 16.4983 13.25 13.3226C13.25 12.8028 13.1812 12.2997 13.0523 11.8217C12.6721 12.2334 12.2524 12.6307 11.7485 12.9577C10.8253 13.5569 9.66993 13.899 8 13.899C7.65775 13.899 7.35891 13.6673 7.27364 13.3358C7.18838 13.0044 7.33833 12.6572 7.63811 12.4921C8.31692 12.1181 8.89232 11.661 9.33445 11.0357C9.77567 10.4118 10.1137 9.57875 10.2555 8.40975C10.5623 5.879 9.51494 3.38107 7.67122 2.25081Z" fill="#5577AA"></path>
                          </svg>
                          <span style={{ color: 'rgb(85, 119, 170)' }}>TASK</span>
                        </div>
                      </a>
                    </div>
                    <a href="/frens">
                      <div class="menu-item three">
                        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 4.5C4.25 2.15279 6.15279 0.25 8.5 0.25C10.8472 0.25 12.75 2.15279 12.75 4.5C12.75 6.84721 10.8472 8.75 8.5 8.75C6.15279 8.75 4.25 6.84721 4.25 4.5ZM8.5 1.75C6.98122 1.75 5.75 2.98122 5.75 4.5C5.75 6.01878 6.98122 7.25 8.5 7.25C10.0188 7.25 11.25 6.01878 11.25 4.5C11.25 2.98122 10.0188 1.75 8.5 1.75Z" fill="#5577AA"></path>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 14C0.75 11.9289 2.42893 10.25 4.5 10.25H4.84087C5.02536 10.25 5.20869 10.2792 5.38407 10.3364L6.24959 10.6191C7.71187 11.0965 9.28813 11.0965 10.7504 10.6191L11.6159 10.3364C11.7913 10.2792 11.9746 10.25 12.1591 10.25H12.5C14.5711 10.25 16.25 11.9289 16.25 14V15.1883C16.25 15.9415 15.7041 16.5837 14.9607 16.7051C10.6819 17.4037 6.3181 17.4037 2.03927 16.7051C1.29588 16.5837 0.75 15.9415 0.75 15.1883V14ZM4.5 11.75C3.25736 11.75 2.25 12.7574 2.25 14V15.1883C2.25 15.2064 2.26311 15.2218 2.28097 15.2247C6.39972 15.8972 10.6003 15.8972 14.719 15.2247C14.7369 15.2218 14.75 15.2064 14.75 15.1883V14C14.75 12.7574 13.7426 11.75 12.5 11.75H12.1591C12.1328 11.75 12.1066 11.7542 12.0815 11.7623L11.216 12.045C9.45119 12.6212 7.54881 12.6212 5.78398 12.045L4.91847 11.7623C4.89342 11.7542 4.86722 11.75 4.84087 11.75H4.5Z" fill="#5577AA"></path>
                          <path d="M17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V5.75H19.5C19.9142 5.75 20.25 6.08579 20.25 6.5C20.25 6.91421 19.9142 7.25 19.5 7.25H17.75V9C17.75 9.41421 17.4142 9.75 17 9.75C16.5858 9.75 16.25 9.41421 16.25 9V7.25H14.5C14.0858 7.25 13.75 6.91421 13.75 6.5C13.75 6.08579 14.0858 5.75 14.5 5.75H16.25V4C16.25 3.58579 16.5858 3.25 17 3.25Z" fill="#5577AA"></path>
                        </svg>
                        <span style={{ color: 'rgb(85, 119, 170)' }}>Frens</span>
                      </div>
                    </a>
                  </div>
                </footer>
              </div>
       </>
  );
};

export default Leaders;