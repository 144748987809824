import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './HomePage.css';

const HomePage = () => {
  const [userId, setUserId] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [coins, setCoins] = useState(0);
  const [lastClaim, setLastClaim] = useState('1970-01-01T00:00:00.132+00:00');
  const [cooldownTime, setCooldownTime] = useState('');
  const [isCooldown, setIsCooldown] = useState(false);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    tg.disableVerticalSwipes();
    tg.ready();

    if (tg) {
      document.documentElement.style.setProperty('--tg-theme-header-bg-color', tg.themeParams.header_bg_color || '#172334');
      const tgData = tg.initDataUnsafe;

      if (tgData && tgData.user && tgData.user.id) {
        const userId = tgData.user.id;
        setUserId(userId);
        setFirstName(tgData.user.first_name);
        setPhotoUrl(tgData.user.photo_url);

        axios.get('https://svrgg.sp1project.ru/api/user-info', { params: { id: userId } })
          .then(response => {
            setCoins(response.data.coins);
            setLastClaim(response.data.lastClaim || '1970-01-01T00:00:00.132+00:00');
            updateCooldown(response.data.lastClaim || '1970-01-01T00:00:00.132+00:00');
          })
          .catch(() => {
            axios.post('https://svrgg.sp1project.ru/api/register-user', { id: userId })
              .then(response => {
                setCoins(response.data.coins);
                setLastClaim('1970-01-01T00:00:00.132+00:00');
                updateCooldown('1970-01-01T00:00:00.132+00:00');
              })
              .catch(error => {
                console.error('Error registering user:', error);
              });
          });
      } else {
        console.error('Telegram data is not available.');
      }
    }
  }, []);

  useEffect(() => {
    if (lastClaim) {
      const interval = setInterval(() => {
        updateCooldown(lastClaim);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [lastClaim]);

  const calculateCooldownTime = (lastClaim) => {
    const now = new Date().getTime();
    const cooldown = 6 * 60 * 60 * 1000; // 6 hours
    const lastClaimTime = new Date(lastClaim).getTime();
    const timeElapsed = now - lastClaimTime;
    const timeRemaining = cooldown - timeElapsed;
    
    if (timeRemaining > 0) {
      const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
      setIsCooldown(true);
      setCooldownTime(`${hours}:${minutes}:${seconds}`);
    } else {
      setIsCooldown(false);
      setCooldownTime('Ready');
    }
  };

  const claimCoins = async () => {
    if (userId) {
      try {
        const response = await axios.post('https://svrgg.sp1project.ru/api/claim-coins', { id: userId });
        if (response.data.success) {
          setCoins(response.data.coins);
          setLastClaim(new Date().toISOString());
        } else {
          const waitTime = response.data.waitTime || 0;
          setLastClaim(new Date(new Date().getTime() - (6 * 60 * 60 * 1000 - waitTime)).toISOString());
        }
      } catch (error) {
        console.error('Error claiming coins:', error);
      }
    } else {
      console.error('User ID is not available.');
    }
  };

  const updateCooldown = (lastClaim) => {
    calculateCooldownTime(lastClaim);
  };

  const navigateTo = (url) => {
    window.location.href = url;
  };
  

  return (
  <div id="root">
    <div id="wrap">
      <div id="content">
        <div class="basic-layout">
          <div class="content">
            <div class="home">
              <div class="user-header">
                <div class="user-content">
                  <div class="user-header-left"></div>
                  <div class="user-avatar">{photoUrl ? <img src={photoUrl} alt="User Avatar" /> : 'f'}
                  <svg class="progress-ring" width="50" height="50">
                    <circle class="progress-ring__circle" stroke="url(#grad1)" stroke-width="3.3" fill="transparent" r="22" cx="25" cy="25" style={{strokedasharray: '138.23', strokedashoffset: '131.444'}}></circle>
                    <defs>
                      <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="37.93%" style={{ stopcolor: 'rgb(20, 140, 248)' , stopopacity: '1'}}></stop>
                        <stop offset="54.95%" style={{ stopcolor: 'rgb(65, 188, 215)', stopopacity: '1'}}></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                  </div>
                  <div class="user-info">
                    <h4 class="user-username">{firstName}</h4>
                    <p class="user-level">Level: soon</p>
                    </div>
                    </div>
                  <div class="team-short-info" style={{ backgroundcolor: 'rgb(20, 140, 248)'}}>
                    <div class="team-empty">
                      <p>У вас еще нет команды</p>
                      <button>Искать</button>
                      </div>
                      <div class="home-wrappper">
                        <div class="user-balance">
                          <p>Баланс</p>
                          <div class="balance-detail">
                            <h4>{coins}</h4>
                            <h5>YD</h5>
                            </div>
                            <div class="game-wrapper">
                              <div class="game-title">
                                <h4>Игры</h4>
                                <p>
                              2
                                </p>
                                </div>
                                <div class="swiper-container">
                                  <div class="swiper swiper-initialized swiper-horizontal swiper-backface-hidden">
                                    <div class="swiper-wrapper" style={{ transitionduration: '0ms', ransitiondelay: '0ms', ransitiondelay: '0ms' }}>
                                      <div class="swiper-slide swiper-slide-active" style={{ marginright: '15px' }}>
                                        <div class="game-item">
                                          <div class="game-image">
                                            <img src="STACK.png"></img>
                                            </div>
                                            <div class="game-content">
                                              <div class="game-item-info">
                                                <div class="game-item-info-logo">
                                                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="12.48" height="12.48" rx="4" fill="white"></rect>
                                                  <rect y="13.52" width="12.48" height="12.48" rx="4" fill="white"></rect>
                                                  <rect x="13.52" width="12.48" height="12.48" rx="4" fill="white"></rect>
                                                  <path d="M15.9728 22.8802C15.5489 22.8802 15.195 22.8085 14.911 22.665C14.6271 22.5168 14.413 22.3255 14.2689 22.0912C14.1248 21.8521 14.0485 21.5963 14.04 21.3238C14.04 21.2807 14.0528 21.2449 14.0782 21.2162C14.1079 21.1827 14.1418 21.166 14.1799 21.166H15.2417C15.3052 21.166 15.354 21.1803 15.3879 21.209C15.4218 21.2377 15.4451 21.2664 15.4578 21.2951C15.5002 21.3764 15.5447 21.4457 15.5913 21.5031C15.6379 21.5557 15.6931 21.5963 15.7566 21.625C15.8202 21.6489 15.8944 21.6609 15.9791 21.6609C16.0724 21.6609 16.1593 21.6393 16.2398 21.5963C16.3203 21.5485 16.3839 21.4816 16.4305 21.3955C16.4814 21.3046 16.5068 21.1947 16.5068 21.0656C16.5068 20.9556 16.4835 20.8599 16.4369 20.7787C16.3945 20.6926 16.3331 20.6233 16.2525 20.5707C16.1762 20.5181 16.0851 20.4918 15.9791 20.4918C15.8944 20.4918 15.8244 20.5037 15.7693 20.5276C15.7185 20.5468 15.6761 20.5707 15.6422 20.5993C15.6083 20.628 15.5765 20.6543 15.5468 20.6782C15.5214 20.6974 15.4917 20.7069 15.4578 20.7069H14.4088C14.3706 20.7069 14.3367 20.6926 14.3071 20.6639C14.2816 20.6304 14.2689 20.5922 14.2689 20.5491L14.5105 18.0029C14.519 17.9264 14.5444 17.869 14.5868 17.8308C14.6334 17.7925 14.6843 17.7734 14.7394 17.7734H17.4351C17.4817 17.7734 17.5198 17.7925 17.5495 17.8308C17.5834 17.8643 17.6004 17.9073 17.6004 17.9599V18.8062C17.6004 18.8541 17.5834 18.8971 17.5495 18.9353C17.5198 18.9736 17.4817 18.9927 17.4351 18.9927H15.585L15.5214 19.71C15.6274 19.6287 15.7397 19.5689 15.8584 19.5307C15.9813 19.4876 16.1508 19.4661 16.367 19.4661C16.5662 19.4661 16.7569 19.5068 16.9392 19.588C17.1257 19.6645 17.291 19.7769 17.4351 19.9251C17.5834 20.0686 17.7 20.2407 17.7847 20.4416C17.8695 20.6424 17.9119 20.8647 17.9119 21.1086C17.9119 21.4337 17.8377 21.7302 17.6894 21.998C17.541 22.2657 17.3227 22.4809 17.0345 22.6435C16.7463 22.8013 16.3924 22.8802 15.9728 22.8802Z" fill="white"></path>
                                                  <path d="M19.8576 22.7941C19.811 22.7941 19.7707 22.7774 19.7368 22.7439C19.7071 22.7056 19.6923 22.6602 19.6923 22.6076V19.4518L18.8976 20.1403C18.8552 20.1738 18.8128 20.1857 18.7704 20.1762C18.7323 20.1618 18.6963 20.1308 18.6624 20.0829L18.1982 19.4016C18.1771 19.3585 18.1686 19.3107 18.1728 19.2581C18.1813 19.2055 18.2067 19.1649 18.2491 19.1362L19.7495 17.8236C19.7707 17.8045 19.7919 17.7925 19.8131 17.7878C19.8343 17.7782 19.8597 17.7734 19.8894 17.7734H20.8876C20.9342 17.7734 20.9745 17.7925 21.0084 17.8308C21.0423 17.8643 21.0592 17.9073 21.0592 17.9599V22.6076C21.0592 22.6602 21.0423 22.7056 21.0084 22.7439C20.9745 22.7774 20.9342 22.7941 20.8876 22.7941H19.8576Z" fill="white"></path>
                                                  <path d="M21.8498 22.7941C21.8031 22.7941 21.7629 22.7774 21.729 22.7439C21.6993 22.7056 21.6845 22.6602 21.6845 22.6076V21.9836C21.6845 21.9502 21.6908 21.8952 21.7035 21.8187C21.7205 21.7422 21.7671 21.6728 21.8434 21.6107L22.2122 21.2162C22.6021 20.891 22.9263 20.6161 23.1849 20.3913C23.4434 20.1618 23.6363 19.9634 23.7634 19.796C23.8948 19.6287 23.9605 19.4733 23.9605 19.3298C23.9605 19.2151 23.9351 19.117 23.8842 19.0358C23.8376 18.9497 23.7423 18.9067 23.5981 18.9067C23.4922 18.9067 23.4074 18.933 23.3438 18.9856C23.2803 19.0382 23.2315 19.1027 23.1976 19.1792C23.1679 19.2557 23.1446 19.3298 23.1277 19.4016C23.1107 19.4637 23.0811 19.5068 23.0387 19.5307C22.9963 19.5546 22.9475 19.5665 22.8924 19.5665H21.9006C21.8582 19.5665 21.8243 19.5522 21.7989 19.5235C21.7735 19.49 21.7608 19.4518 21.7608 19.4087C21.765 19.1553 21.8116 18.9258 21.9006 18.7202C21.9939 18.5098 22.121 18.3257 22.2821 18.1679C22.4432 18.0101 22.6339 17.8906 22.8543 17.8093C23.0789 17.7232 23.3248 17.6802 23.5918 17.6802C23.9648 17.6802 24.2848 17.7447 24.5518 17.8738C24.8188 18.0029 25.0244 18.1846 25.1685 18.4189C25.3126 18.6532 25.3847 18.933 25.3847 19.2581C25.3847 19.5068 25.3338 19.7387 25.2321 19.9538C25.1304 20.169 24.9863 20.3746 24.7998 20.5707C24.6133 20.7619 24.3929 20.9604 24.1386 21.166L23.827 21.5102H25.3147C25.3614 21.5102 25.3995 21.5294 25.4292 21.5676C25.4631 21.6059 25.48 21.6513 25.48 21.7039V22.6076C25.48 22.6602 25.4631 22.7056 25.4292 22.7439C25.3995 22.7774 25.3614 22.7941 25.3147 22.7941H21.8498Z" fill="white"></path>
                                                  </svg>
                                                  </div>
                                                  </div>
                                                  </div>
                                                    <div class="game-content">
                                                      <div class="game-item-info">
                                                        <div class="game-item-info-logo">
                                                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                          <path d="M10.0208 0.22334C10.6416 -0.0731445 11.3583 -0.0731445 11.9791 0.22334L21.0874 4.56318C21.4416 4.73076 21.6666 5.096 21.6666 5.4999C21.6666 5.90381 21.4416 6.26904 21.0874 6.43662L11.9791 10.7765C11.3583 11.073 10.6416 11.073 10.0208 10.7765L0.912419 6.43662C0.558252 6.26475 0.333252 5.89951 0.333252 5.4999C0.333252 5.10029 0.558252 4.73076 0.912419 4.56318L10.0208 0.22334ZM18.8708 9.00615L21.0874 10.0632C21.4416 10.2308 21.6666 10.596 21.6666 10.9999C21.6666 11.4038 21.4416 11.769 21.0874 11.9366L11.9791 16.2765C11.3583 16.5729 10.6416 16.5729 10.0208 16.2765L0.912419 11.9366C0.558252 11.7647 0.333252 11.3995 0.333252 10.9999C0.333252 10.6003 0.558252 10.2308 0.912419 10.0632L3.12909 9.00615L9.46242 12.0226C9.94449 12.2519 10.469 12.3706 10.9999 12.3706C11.5308 12.3706 12.0554 12.2519 12.5374 12.0226L18.8708 9.00615ZM12.5374 17.5226L18.8708 14.5062L21.0874 15.5632C21.4416 15.7308 21.6666 16.096 21.6666 16.4999C21.6666 16.9038 21.4416 17.269 21.0874 17.4366L11.9791 21.7765C11.3583 22.073 10.6416 22.073 10.0208 21.7765L0.912419 17.4366C0.558252 17.2647 0.333252 16.8995 0.333252 16.4999C0.333252 16.1003 0.558252 15.7308 0.912419 15.5632L3.12909 14.5062L9.46242 17.5226C9.94449 17.7519 10.469 17.8706 10.9999 17.8706C11.5308 17.8706 12.0554 17.7519 12.5374 17.5226Z" fill="white"></path>
                                                          </svg>
                                                          </div>
                                                          <div class="game-item-info-main">
                                                            <h4>Stack</h4>
                                                            <p>Давай выше!</p>
                                                            </div>
                                                            </div>
                                                            <button class="game-item-button">
                                                            <a href="https://stack-ts.vercel.app/">Играть 
                                                            </a>
                                                              </button>
                                                            </div>
                                                            </div>
                                        </div>
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                  <button style={{background: "rgb(17, 26, 40)" , color: "rgb(51, 51, 51)" , border: "none"}}>
                                  <div class="progress-bar" style={{width: '0px-127.549px' }}>
                                    <div class="progress-bar-liquid"></div>
                                  </div>
                                  <div class="farm-stats">
                                    <p>
                                  
                                    </p>
                                    <span></span>
                                    </div>
                                    </button>
                                  </div>
                                  <div class="farm-timer">
                                    <div class="image-wrapper">
                                      <img src="yodahome.png"  style={{ width: '250px', marginbottom: '-5px'}}></img>
                                    </div>
                                  <button className="farm-wrapper"  type="button" onClick={claimCoins} disabled={isCooldown}>ЗАБРАТЬ 56 YD   </button>
                                  {isCooldown && (
                               <div className="farm-timer">ОСТАЛОСЬ: {cooldownTime}</div>)}
                        </div>
                        {Array.from({ length: 56 }).map((_, index) => (
          <div className="star" key={index}></div>
        ))}
                      </div>
                    </div>
                </div>
              </div>
              <footer class="footer">
                <div class="main-menu">
                  <a href="/">
                  <div class="menu-item first">
                    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.2958 1.13597C9.31335 0.121347 7.6861 0.121345 6.70369 1.13596L1.90521 6.09169C1.62963 6.37631 1.44444 6.73612 1.37299 7.12579C0.790227 10.3038 0.74721 13.5572 1.24574 16.7495L1.42225 17.8797C1.478 18.2367 1.78546 18.4998 2.14677 18.4998H5.49973C5.77587 18.4998 5.99973 18.276 5.99973 17.9998V10.9998H10.9997V17.9998C10.9997 18.276 11.2236 18.4998 11.4997 18.4998H14.8526C15.2139 18.4998 15.5214 18.2367 15.5772 17.8797L15.7537 16.7495C16.2522 13.5572 16.2092 10.3038 15.6264 7.12579C15.555 6.73612 15.3698 6.37631 15.0942 6.0917L10.2958 1.13597Z" fill="#fff"></path>
                    </svg>
                    <span style={{ color: 'rgb(255, 255, 255)'}}>Home</span>
                    </div>
                  </a>
                  <div class="center-container">
                    <a href="/tasks">
                    <div class="menu-item 2">
                      <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.00666 0.508036C6.18839 0.298945 6.47057 0.207052 6.74057 0.269038C10.3494 1.09755 12.1735 5.05217 11.7445 8.5903C11.5778 9.96568 11.1658 11.0439 10.5592 11.9018C10.5555 11.9071 10.5517 11.9123 10.548 11.9175C10.6858 11.8498 10.8129 11.7767 10.9319 11.6995C11.5238 11.3153 11.9583 10.8083 12.5239 10.1481C12.5923 10.0683 12.6626 9.98629 12.7353 9.90196C12.8987 9.71247 13.1459 9.61695 13.3942 9.64729C13.6426 9.67764 13.8595 9.82985 13.9725 10.0531C14.47 11.0363 14.75 12.1479 14.75 13.3226C14.75 17.3267 11.5041 20.5726 7.5 20.5726C3.49594 20.5726 0.25 17.3267 0.25 13.3226C0.25 10.7856 1.55371 8.5535 3.52431 7.25906L3.59187 7.19098C3.62658 7.15601 3.66464 7.12454 3.70552 7.09703C4.57138 6.5144 5.19312 6.03786 5.61171 5.47743C6.00713 4.94801 6.25 4.3022 6.25 3.32264C6.25 2.59736 6.11608 1.90512 5.87229 1.26809C5.77327 1.00936 5.82494 0.717126 6.00666 0.508036ZM7.67122 2.25081C7.72313 2.60079 7.75 2.95872 7.75 3.32264C7.75 4.59106 7.42229 5.55995 6.81349 6.37505C6.24173 7.14054 5.45028 7.72847 4.60466 8.2999L4.53236 8.37275C4.49362 8.41179 4.45071 8.44644 4.40439 8.4761C2.80648 9.49913 1.75 11.2877 1.75 13.3226C1.75 16.4983 4.32436 19.0726 7.5 19.0726C10.6756 19.0726 13.25 16.4983 13.25 13.3226C13.25 12.8028 13.1812 12.2997 13.0523 11.8217C12.6721 12.2334 12.2524 12.6307 11.7485 12.9577C10.8253 13.5569 9.66993 13.899 8 13.899C7.65775 13.899 7.35891 13.6673 7.27364 13.3358C7.18838 13.0044 7.33833 12.6572 7.63811 12.4921C8.31692 12.1181 8.89232 11.661 9.33445 11.0357C9.77567 10.4118 10.1137 9.57875 10.2555 8.40975C10.5623 5.879 9.51494 3.38107 7.67122 2.25081Z" fill="#5577AA"></path>
                    </svg>
                    <span style={{ color: 'rgb(85, 119, 170)' }}>TASK</span>
                    </div>
                    </a>
                    </div>
                    <a href="/frens">
                    <div class="menu-item three">
                      <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 4.5C4.25 2.15279 6.15279 0.25 8.5 0.25C10.8472 0.25 12.75 2.15279 12.75 4.5C12.75 6.84721 10.8472 8.75 8.5 8.75C6.15279 8.75 4.25 6.84721 4.25 4.5ZM8.5 1.75C6.98122 1.75 5.75 2.98122 5.75 4.5C5.75 6.01878 6.98122 7.25 8.5 7.25C10.0188 7.25 11.25 6.01878 11.25 4.5C11.25 2.98122 10.0188 1.75 8.5 1.75Z" fill="#5577AA"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 14C0.75 11.9289 2.42893 10.25 4.5 10.25H4.84087C5.02536 10.25 5.20869 10.2792 5.38407 10.3364L6.24959 10.6191C7.71187 11.0965 9.28813 11.0965 10.7504 10.6191L11.6159 10.3364C11.7913 10.2792 11.9746 10.25 12.1591 10.25H12.5C14.5711 10.25 16.25 11.9289 16.25 14V15.1883C16.25 15.9415 15.7041 16.5837 14.9607 16.7051C10.6819 17.4037 6.3181 17.4037 2.03927 16.7051C1.29588 16.5837 0.75 15.9415 0.75 15.1883V14ZM4.5 11.75C3.25736 11.75 2.25 12.7574 2.25 14V15.1883C2.25 15.2064 2.26311 15.2218 2.28097 15.2247C6.39972 15.8972 10.6003 15.8972 14.719 15.2247C14.7369 15.2218 14.75 15.2064 14.75 15.1883V14C14.75 12.7574 13.7426 11.75 12.5 11.75H12.1591C12.1328 11.75 12.1066 11.7542 12.0815 11.7623L11.216 12.045C9.45119 12.6212 7.54881 12.6212 5.78398 12.045L4.91847 11.7623C4.89342 11.7542 4.86722 11.75 4.84087 11.75H4.5Z" fill="#5577AA"></path>
                      <path d="M17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V5.75H19.5C19.9142 5.75 20.25 6.08579 20.25 6.5C20.25 6.91421 19.9142 7.25 19.5 7.25H17.75V9C17.75 9.41421 17.4142 9.75 17 9.75C16.5858 9.75 16.25 9.41421 16.25 9V7.25H14.5C14.0858 7.25 13.75 6.91421 13.75 6.5C13.75 6.08579 14.0858 5.75 14.5 5.75H16.25V4C16.25 3.58579 16.5858 3.25 17 3.25Z" fill="#5577AA"></path>
                      </svg>
                      <span style={{color: 'rgb(85, 119, 170)' }}>Frens</span>
                      </div>
                    </a>
                    </div>
                    </footer>
            </div>
          </div>
        </div>
        <div style={{position: '' , zindex: '9999', inset: '16px', pointerevents: 'none' }}></div>
      </div>
      <script src="https://unpkg.com/@telegram-apps/sdk@1.0.0/dist/index.iife.js"></script>
      <iframe height="1" width="1" style={{ position: 'absolute', top: '0px', left: '0px', border: 'none' , visibility: 'hidden'}}>
      </iframe>
    </div>
  );
};

export default HomePage;