import React from 'react';
import { useNavigate } from 'react-router-dom';
import './TaskPage.css';


const TaskPage = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <>
  <div id="root">
    <div id="wrap">
        <div id="content">
            <div class="basic-layout">
                <div class="content">
                </div>
                <div class="header-rewards">
                    <div class="header-tabs">
                        <a href="/rewards/tasks" style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)'}}>Задания</a>
                        <a href="/rewards/bonus" style={{ color: 'rgb(255, 255, 255)'}}>Бонусы</a>
                    </div>
                    <div class="header-title">
                        <h3>Задания</h3>
                    </div>
                </div>
                <div class="content-rewards">
                    <div class="home-wrapper">
                        <div>
                            <div class="tasks-wrapper">
                                <h4>Подписаться</h4>
                                <div class="clayton-group-item">
                                    <div class="clayton-group-left">
                                        <div class="clayton-group-image">
                                            <img src="yoda.png"></img>
                                        </div>
                                        <div class="clayton-group-info">
                                            <h4>YodaTon</h4>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div class="clayton-group-right">
                                    <button class="button-start">
                                        <a href="https://t.me/YodaTonYoda">Start
                                        </a>
                                        </button>
                                    </div>
                                </div>
                                <div class="clayton-twitter-item">
                                    <div class="clayton-twitter-left">
                                        <div class="clayton-twitter-image">
                                            <img src="subscribe.jpg"></img>
                                        </div>
                                        <div class="clayton-twitter-info">
                                            <h4>
                                                Мамкин Трейдер •
                                                <span> 150 YD</span>
                                            </h4>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div class="clayton-twitter-right">
                                        <div class="done-task">
                                        <button class="button-start">
                                        <a href="https://t.me/+LpeMPW0b4NxiY2Y6">Start
                                        </a>
                                        </button>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tasks-wrapper">
                                <h4>Другие</h4>
                                <div class="clayton-twitter-item">
                                    <div class="clayton-twitter-left">
                                        <div class="clayton-group-image">
                                        <img src="yoda.png"></img>
                                        </div>
                                        <div class="clayton-twitter-info">
                                            <h4>
                                                Используй бота
                                                 •  
                                                <span> 100 YD   </span>
                                            </h4>
                                        </div>
                                    </div>
                                    <div class="alpha-right">
                                        <button class="button-start">
                                        <a href="https://t.me/botbot_lesson_bot">Start
                                        </a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer">
            <div class="main-menu">
            <a href="/">
                  <div class="menu-item first">
                    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0579 2.53472C9.18732 1.69936 7.81281 1.69936 6.94224 2.53472L2.3158 6.97405C2.20245 7.08282 2.1262 7.22451 2.09787 7.37903C1.54373 10.4009 1.50283 13.4945 1.97687 16.53L2.08939 17.2505H5.06585V11.0391C5.06585 10.6249 5.40164 10.2891 5.81585 10.2891H11.1843C11.5985 10.2891 11.9343 10.6249 11.9343 11.0391V17.2505H14.9107L15.0232 16.53C15.4973 13.4945 15.4564 10.4009 14.9023 7.37903C14.8739 7.22451 14.7977 7.08282 14.6843 6.97406L10.0579 2.53472ZM5.90369 1.4524C7.35464 0.0601368 9.64549 0.0601368 11.0964 1.4524L15.7229 5.89174C16.0634 6.21853 16.2925 6.64422 16.3777 7.10848C16.9622 10.2961 17.0053 13.5594 16.5053 16.7614L16.3245 17.9189C16.2498 18.3976 15.8375 18.7505 15.353 18.7505H11.1843C10.7701 18.7505 10.4343 18.4147 10.4343 18.0005V11.7891H6.56585V18.0005C6.56585 18.4147 6.23007 18.7505 5.81585 18.7505H1.64712C1.16264 18.7505 0.750355 18.3976 0.6756 17.9189L0.494837 16.7614C-0.00521103 13.5594 0.0379363 10.2961 0.622466 7.10848C0.707599 6.64422 0.936683 6.21853 1.27725 5.89173L5.90369 1.4524Z" fill="#5577AA" fill-rule="evenodd" clip-rule="evenodd"></path>
                    </svg>
                    <span style={{ color: 'rgb(85, 119, 170)'}}>Home</span>
                    </div>
                  </a>
                  <div class="center-container">
                    <a href="">
                    <div class="menu-item 2">
                      <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.56254 0.0648393C7.27291 -0.0621456 6.98765 0.0266894 6.80414 0.213577C6.62588 0.395117 6.53673 0.67219 6.59923 0.951544C6.6979 1.39249 6.75 1.85124 6.75 2.32246C6.75 3.3983 6.47885 4.15181 6.0123 4.77645C5.53803 5.41143 4.85149 5.92839 3.98466 6.51168C3.97103 6.52084 3.95834 6.53133 3.94678 6.54299L3.84179 6.64879C1.98195 7.85034 0.75 9.94245 0.75 12.3225C0.75 16.0504 3.77208 19.0725 7.5 19.0725C11.2279 19.0725 14.25 16.0504 14.25 12.3225C14.25 11.2279 13.9893 10.1935 13.5264 9.27869C13.4887 9.20427 13.4164 9.15353 13.3336 9.14342C13.2508 9.1333 13.1684 9.16514 13.114 9.22831C13.0435 9.31006 12.9744 9.39074 12.9064 9.47024C12.0281 10.4964 11.3193 11.3246 9.86616 11.6945C9.80436 11.7102 9.76597 11.6985 9.73873 11.6815C9.70667 11.6615 9.6716 11.6215 9.64757 11.5576C9.59748 11.4245 9.61897 11.2547 9.72161 11.1434C10.4988 10.3008 11.0509 9.15705 11.2482 7.52993C11.6177 4.48203 10.2325 1.23543 7.56254 0.0648393Z" fill="white"></path>
                    </svg>
                    <span style={{ color: 'rgb(255, 255, 255)' }}>TASK</span>
                    </div>
                    </a>
                    </div>
                    <a href="/frens">
                    <div class="menu-item three">
                      <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 4.5C4.25 2.15279 6.15279 0.25 8.5 0.25C10.8472 0.25 12.75 2.15279 12.75 4.5C12.75 6.84721 10.8472 8.75 8.5 8.75C6.15279 8.75 4.25 6.84721 4.25 4.5ZM8.5 1.75C6.98122 1.75 5.75 2.98122 5.75 4.5C5.75 6.01878 6.98122 7.25 8.5 7.25C10.0188 7.25 11.25 6.01878 11.25 4.5C11.25 2.98122 10.0188 1.75 8.5 1.75Z" fill="#5577AA"></path>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 14C0.75 11.9289 2.42893 10.25 4.5 10.25H4.84087C5.02536 10.25 5.20869 10.2792 5.38407 10.3364L6.24959 10.6191C7.71187 11.0965 9.28813 11.0965 10.7504 10.6191L11.6159 10.3364C11.7913 10.2792 11.9746 10.25 12.1591 10.25H12.5C14.5711 10.25 16.25 11.9289 16.25 14V15.1883C16.25 15.9415 15.7041 16.5837 14.9607 16.7051C10.6819 17.4037 6.3181 17.4037 2.03927 16.7051C1.29588 16.5837 0.75 15.9415 0.75 15.1883V14ZM4.5 11.75C3.25736 11.75 2.25 12.7574 2.25 14V15.1883C2.25 15.2064 2.26311 15.2218 2.28097 15.2247C6.39972 15.8972 10.6003 15.8972 14.719 15.2247C14.7369 15.2218 14.75 15.2064 14.75 15.1883V14C14.75 12.7574 13.7426 11.75 12.5 11.75H12.1591C12.1328 11.75 12.1066 11.7542 12.0815 11.7623L11.216 12.045C9.45119 12.6212 7.54881 12.6212 5.78398 12.045L4.91847 11.7623C4.89342 11.7542 4.86722 11.75 4.84087 11.75H4.5Z" fill="#5577AA"></path>
                      <path d="M17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V5.75H19.5C19.9142 5.75 20.25 6.08579 20.25 6.5C20.25 6.91421 19.9142 7.25 19.5 7.25H17.75V9C17.75 9.41421 17.4142 9.75 17 9.75C16.5858 9.75 16.25 9.41421 16.25 9V7.25H14.5C14.0858 7.25 13.75 6.91421 13.75 6.5C13.75 6.08579 14.0858 5.75 14.5 5.75H16.25V4C16.25 3.58579 16.5858 3.25 17 3.25Z" fill="#5577AA"></path>
                      </svg>
                      <span style={{color: 'rgb(85, 119, 170)' }}>Frens</span>
                      </div>
                    </a>
                    </div>
                    </footer>              
    </div>
  </div>
   <div style={{position: '' , zindex: '9999', inset: '16px', pointerevents: 'none' }}>
      <script src="https://unpkg.com/@telegram-apps/sdk@1.0.0/dist/index.iife.js"></script>
      <iframe height="1" width="1" style={{ position: 'absolute', top: '0px', left: '0px', border: 'none' , visibility: 'hidden'}}>
      </iframe>
      </div>
    </>
  );
};


export default TaskPage;