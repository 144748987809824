import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import TaskPage from './components/TaskPage';
import FrensPage from './components/FrensPage';
import Leaders from './components/Leaders'

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const timer = setTimeout(() => {
      setIsLoading(false); 
    }, 1250);

    const handleWebAppReady = () => {
      clearTimeout(timer); 
      setIsLoading(false); 
    };

    if (tg) {
      tg.ready(handleWebAppReady);
    } else {
      console.error('Telegram WebApp не доступен.');
      setIsLoading(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    if (tg) {
      document.documentElement.style.setProperty('--tg-theme-header-bg-color', tg.themeParams.header_bg_color || '#111a28');
    }
  }, []);

  return (
    <div id="app">
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/tasks" element={<TaskPage />} />
            <Route path="/frens" element={<FrensPage />} />
            <Route path="/leaders" element={<Leaders />} />
          </Routes>
        </Router>
    </div>
  );
};

export default App;
