import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './FrensPage.css';

const FrensPage = () => {
  const navigate = useNavigate();
  const navigateTo = (path) => {
    navigate(path);
  };

  const tg = window.Telegram.WebApp;
  const userId = tg.initDataUnsafe?.user?.id;

  const referralLink = `https://t.me/YodaTon_bot?start=r_${userId}`;

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); 
    });
  };

  return (
    <>
  <div id="root">
    <div id="wrap">
        <div id="content">
            <div class="basic-layout">
                <div class="content">
                </div>
                <div class="header-community">
                    <div class="header-tabs">
                        <a href="/rewards/tasks" style={{ background: 'rgb(255, 255, 255)', color: 'rgb(0, 0, 0)'}}>Друзья</a>
                        <a href="/leaders" style={{ color: 'rgb(255, 255, 255)'}}>Лидеры</a>
                    </div>
                    <div class="header-title">
                        <h3>Друзья</h3>
                    </div>
                </div>
                <div class="home-wrapper">
                    <div class="community-friends">
                        <div class="community-friends-title">
                            <h4>Приглашайте друзей и получайте больше поинтов!</h4>
                            <div class="community-friends-proc">
                                <div class="community-left">
                                    <div class="community-top">
                                        <h4>👨&zwj;👩&zwj;👧&zwj;👦10%</h4>
                                    </div>
                                    <div class="community-bottom">Баллы за друга без Premium tg</div>
                                </div>
                                <div class="community-right">
                                    <div class="community-top">
                                        <h4>🌟25%</h4>
                                    </div>
                                    <div class="community-bottom">Баллы за друга с Premium tg</div>
                                </div>
                            </div>
                        </div>
                        <div class="empty-friends">
                            <div class="empty-images">
                                <img src="yodafrens.png"></img>
                            </div>
                            <div class="empty-info">
                                <h5>Список друзей пуст</h5>
                                <p>Пригласите своего первого друга, используя кнопку ниже, и получите бонус</p>
                            </div>
                        </div>
                        <div className="copy-button">
                    <button onClick={copyToClipboard}>
                      {isCopied ? 'Ссылка скопирована!' : 'Скопировать ссылку'}
                    </button>
                  </div>
                    </div>
                    </div>
                    </div>
                    </div>
        <footer class="footer">
            <div class="main-menu">
            <a href="/">
                  <div class="menu-item first">
                    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0579 2.53472C9.18732 1.69936 7.81281 1.69936 6.94224 2.53472L2.3158 6.97405C2.20245 7.08282 2.1262 7.22451 2.09787 7.37903C1.54373 10.4009 1.50283 13.4945 1.97687 16.53L2.08939 17.2505H5.06585V11.0391C5.06585 10.6249 5.40164 10.2891 5.81585 10.2891H11.1843C11.5985 10.2891 11.9343 10.6249 11.9343 11.0391V17.2505H14.9107L15.0232 16.53C15.4973 13.4945 15.4564 10.4009 14.9023 7.37903C14.8739 7.22451 14.7977 7.08282 14.6843 6.97406L10.0579 2.53472ZM5.90369 1.4524C7.35464 0.0601368 9.64549 0.0601368 11.0964 1.4524L15.7229 5.89174C16.0634 6.21853 16.2925 6.64422 16.3777 7.10848C16.9622 10.2961 17.0053 13.5594 16.5053 16.7614L16.3245 17.9189C16.2498 18.3976 15.8375 18.7505 15.353 18.7505H11.1843C10.7701 18.7505 10.4343 18.4147 10.4343 18.0005V11.7891H6.56585V18.0005C6.56585 18.4147 6.23007 18.7505 5.81585 18.7505H1.64712C1.16264 18.7505 0.750355 18.3976 0.6756 17.9189L0.494837 16.7614C-0.00521103 13.5594 0.0379363 10.2961 0.622466 7.10848C0.707599 6.64422 0.936683 6.21853 1.27725 5.89173L5.90369 1.4524Z" fill="#5577AA" fill-rule="evenodd" clip-rule="evenodd"></path>
                    </svg>
                    <span style={{ color: 'rgb(85, 119, 170)'}}>Home</span>
                    </div>
                  </a>
                  <div class="center-container">
                    <a href="/tasks">
                    <div class="menu-item 2">
                      <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M6.00666 0.508036C6.18839 0.298945 6.47057 0.207052 6.74057 0.269038C10.3494 1.09755 12.1735 5.05217 11.7445 8.5903C11.5778 9.96568 11.1658 11.0439 10.5592 11.9018C10.5555 11.9071 10.5517 11.9123 10.548 11.9175C10.6858 11.8498 10.8129 11.7767 10.9319 11.6995C11.5238 11.3153 11.9583 10.8083 12.5239 10.1481C12.5923 10.0683 12.6626 9.98629 12.7353 9.90196C12.8987 9.71247 13.1459 9.61695 13.3942 9.64729C13.6426 9.67764 13.8595 9.82985 13.9725 10.0531C14.47 11.0363 14.75 12.1479 14.75 13.3226C14.75 17.3267 11.5041 20.5726 7.5 20.5726C3.49594 20.5726 0.25 17.3267 0.25 13.3226C0.25 10.7856 1.55371 8.5535 3.52431 7.25906L3.59187 7.19098C3.62658 7.15601 3.66464 7.12454 3.70552 7.09703C4.57138 6.5144 5.19312 6.03786 5.61171 5.47743C6.00713 4.94801 6.25 4.3022 6.25 3.32264C6.25 2.59736 6.11608 1.90512 5.87229 1.26809C5.77327 1.00936 5.82494 0.717126 6.00666 0.508036ZM7.67122 2.25081C7.72313 2.60079 7.75 2.95872 7.75 3.32264C7.75 4.59106 7.42229 5.55995 6.81349 6.37505C6.24173 7.14054 5.45028 7.72847 4.60466 8.2999L4.53236 8.37275C4.49362 8.41179 4.45071 8.44644 4.40439 8.4761C2.80648 9.49913 1.75 11.2877 1.75 13.3226C1.75 16.4983 4.32436 19.0726 7.5 19.0726C10.6756 19.0726 13.25 16.4983 13.25 13.3226C13.25 12.8028 13.1812 12.2997 13.0523 11.8217C12.6721 12.2334 12.2524 12.6307 11.7485 12.9577C10.8253 13.5569 9.66993 13.899 8 13.899C7.65775 13.899 7.35891 13.6673 7.27364 13.3358C7.18838 13.0044 7.33833 12.6572 7.63811 12.4921C8.31692 12.1181 8.89232 11.661 9.33445 11.0357C9.77567 10.4118 10.1137 9.57875 10.2555 8.40975C10.5623 5.879 9.51494 3.38107 7.67122 2.25081Z" fill="#5577AA" fill-rule="evenodd" clip-rule="evenodd"></path>
                    </svg>
                    <span style={{ color: 'rgb(85, 119, 170)' }}>TASK</span>
                    </div>
                    </a>
                    </div>
                    <a href="">
                    <div class="menu-item three">
                      <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.5 0.75C6.42893 0.75 4.75 2.42893 4.75 4.5C4.75 6.57107 6.42893 8.25 8.5 8.25C10.5711 8.25 12.25 6.57107 12.25 4.5C12.25 2.42893 10.5711 0.75 8.5 0.75Z" fill="white"></path>
                      <path d="M4.5 10.25C2.42893 10.25 0.75 11.9289 0.75 14V15.1883C0.75 15.9415 1.29588 16.5837 2.03927 16.7051C6.3181 17.4037 10.6819 17.4037 14.9607 16.7051C15.7041 16.5837 16.25 15.9415 16.25 15.1883V14C16.25 11.9289 14.5711 10.25 12.5 10.25H12.1591C11.9746 10.25 11.7913 10.2792 11.6159 10.3364L10.7504 10.6191C9.28813 11.0965 7.71187 11.0965 6.24959 10.6191L5.38407 10.3364C5.20869 10.2792 5.02536 10.25 4.84087 10.25H4.5Z" fill="white"></path>
                      <path d="M17 3.25C17.4142 3.25 17.75 3.58579 17.75 4V5.75H19.5C19.9142 5.75 20.25 6.08579 20.25 6.5C20.25 6.91421 19.9142 7.25 19.5 7.25H17.75V9C17.75 9.41421 17.4142 9.75 17 9.75C16.5858 9.75 16.25 9.41421 16.25 9V7.25H14.5C14.0858 7.25 13.75 6.91421 13.75 6.5C13.75 6.08579 14.0858 5.75 14.5 5.75H16.25V4C16.25 3.58579 16.5858 3.25 17 3.25Z" fill="white"></path>
                      </svg>
                      <span style={{color: 'rgb(255, 255, 255)' }}>Frens</span>
                      </div>
                    </a>
                    </div>
                    </footer>              
    </div>
  </div>
   <div style={{position: '' , zindex: '9999', inset: '16px', pointerevents: 'none' }}>
      <script src="https://unpkg.com/@telegram-apps/sdk@1.0.0/dist/index.iife.js"></script>
      <iframe height="1" width="1" style={{ position: 'absolute', top: '0px', left: '0px', border: 'none' , visibility: 'hidden'}}>
      </iframe>
      </div>
    </>
  );
};


export default FrensPage;